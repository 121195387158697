import * as React from "react"
import {baTracker} from "../services/tracking/Mixpanel";

const NotFoundPage = ({path}) => {
    baTracker.track({name: path, pageName: 'Not Found'});
    return (
        <div className={'container mx-auto flex flex-col justify-center min-h-[70vh] space-y-4 p-4 text-center pt-[100px]'}>
            <h2 className={'text-2xl text-black'}>Page not found</h2>
            <p className={'text-xl'}>Sorry, we couldn’t find what you were looking for. This could be our fault when changing the article's path.</p>
            <p className={'text-xl'}>Please go home on the top menu, thank you.</p>
        </div>
    )
}

export default NotFoundPage
